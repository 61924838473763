import { Company } from './companies';
import { FeedbackPriority } from './feedback-priority';
import { GetFilterDropdown, Products } from './feedback-product';
import { FeedbackStatus } from './feedback-status';
import { FeedbackType } from './feedback-type';
import { FilterMetadata, SubFilterMetadata } from './filterConfigurationTypes';

export const FilterConfig: FilterMetadata = {
  AssetTypeId: {
    key: 'AssetTypeId',
    type: 'select',
    name: 'Asset Type',
    options: [
      { key: 'screwPackage', label: 'Screw Package' },
      { key: 'bareScrew', label: 'Bare Screw' },
      { key: 'piston', label: 'Bare Piston' },
      { key: 'omni', label: 'Omni' },
    ],
  },
  AnnunciationStatus: {
    key: 'annun_status',
    type: 'multiSelect',
    name: 'Annunciation Status',
    isClientSide: true,
    clientFilterFunction: () => {},
    options: [
      { key: 0, label: 'Normal' },
      { key: 1, label: 'Warning' },
      { key: 2, label: 'Shutdown' },
    ],
  },
  FeedbackType: {
    key: 'TypeId',
    type: 'multiSelect',
    name: 'Type',
    options: [
      { key: FeedbackType.Feedback, label: 'Feedback' },
      { key: FeedbackType.FeatureRequest, label: 'Feature Request' },
      { key: FeedbackType.ProblemBug, label: 'Problem / Bug' },
    ],
  },
  FeedbackPriority: {
    key: 'PriorityId',
    type: 'multiSelect',
    name: 'Priority',
    options: [
      { key: FeedbackPriority.Na, label: 'N/A' },
      { key: FeedbackPriority.Low, label: 'Low' },
      { key: FeedbackPriority.Medium, label: 'Medium' },
      { key: FeedbackPriority.High, label: 'High' },
      { key: FeedbackPriority.Urgent, label: 'Urgent' },
    ],
  },
  FeedbackStatus: {
    key: 'StatusId',
    type: 'multiSelect',
    name: 'Status',
    options: [
      { key: FeedbackStatus.New, label: 'New' },
      { key: FeedbackStatus.InReview, label: 'In Review' },
      { key: FeedbackStatus.Approved, label: 'Approved' },
      { key: FeedbackStatus.InDevelopment, label: 'In Development' },
      { key: FeedbackStatus.ReadyForTest, label: 'Ready For Test' },
      { key: FeedbackStatus.Done, label: 'Done' },
      { key: FeedbackStatus.Rejected, label: 'Rejected' },
      { key: FeedbackStatus.Deleted, label: 'Deleted' },
    ],
  },
  Company: {
    key: 'CompanyIds',
    type: 'multiSelect',
    name: 'Company',
    options: [
      { key: Company.None, label: 'None' },
      { key: Company.York, label: 'GEA York' },
      { key: Company.Berlin, label: 'GEA Berlin' },
      { key: Company.DenBosch, label: "GEA 's-Hertogenbosch" },
      { key: Company.EmeaSkids, label: 'GEA EMEA Skids' },
      {
        key: Company.EmeaSkidsServiceSales,
        label: 'GEA EMEA Skids (Service Sales)',
      },
      {
        key: Company.DenBoschServiceSales,
        label: "GEA 's-Hertogenbosch (Service Sales)",
      },
    ],
  },
  InternalOnly: {
    key: 'InternalOnly',
    type: 'select',
    name: 'Internal Only',
    internalOnly: true,
    options: [
      { key: false, label: 'No' },
      { key: true, label: 'Yes' },
    ],
  },
  PmDiscussion: {
    key: 'PmDiscussion',
    type: 'select',
    name: 'PM Discussion',
    internalOnly: true,
    options: [
      { key: false, label: 'No' },
      { key: true, label: 'Yes' },
    ],
  },
  Manufacturer: {
    key: 'Manufacturer',
    type: 'multiSelect',
    name: 'Manufacturer',
    options: [
      { key: 'grasso', label: 'Grasso' },
      { key: 'vilter', label: 'Vilter' },
      { key: 'fes', label: 'FES' },
      { key: 'york', label: 'GEA York' },
    ],
  },
  Model: {
    key: 'Model',
    type: 'select',
    name: 'Model',
    options: [
      { key: 'xa', label: 'XA' },
      { key: 'sp1', label: 'SP1 Retrofit' },
      { key: 'ks28mnb', label: 'KS28MNB' },
    ],
  },
  RepOffice: {
    key: 'SalesRep',
    type: 'autoMultiSelect',
    placeholder: 'Search Rep',
    name: 'Rep Name',
    prefetch: true,
  },
  SalesManager: {
    key: 'SalesManager',
    type: 'autoMultiSelect',
    placeholder: 'Search Sales Manager',
    name: 'Sales Manager',
    prefetch: true,
  },
  ProjectManager: {
    key: 'ProjectManager',
    type: 'autoMultiSelect',
    placeholder: 'Search Project Manager',
    name: 'Project Manager',
    prefetch: true,
  },
  Planner: {
    key: 'Planner',
    type: 'autoMultiSelect',
    placeholder: 'Search Planner',
    name: 'Planner',
    prefetch: true,
  },
  EndUser: {
    key: 'EndUser',
    type: 'autoMultiSelect',
    placeholder: 'Search End User',
    name: 'End User',
    prefetch: true,
  },
  Customer: {
    key: 'Customer',
    type: 'autoMultiSelect',
    placeholder: 'Search Customer',
    name: 'Customer',
    prefetch: true,
  },
  OSState: {
    key: 'State',
    type: 'autoMultiSelect',
    placeholder: 'Search State',
    name: 'State',
    prefetch: true,
  },
  CompressorModels: {
    key: 'ItemDescription',
    type: 'groupedMultiSelect',
    name: 'Compressor Model',
    placeholder: 'Search Compressors',
    groupedOptions: [
      {
        name: 'GMX Series',
        options: [
          { key: '55GMX', label: '55GMX' },
          { key: '60GMX', label: '60GMX' },
          { key: '75GMX', label: '75GMX' },
          { key: '85GMX', label: '85GMX' },
          { key: '110GMX', label: '110GMX' },
          { key: '125GMX', label: '125GMX' },
          { key: '160GMX', label: '160GMX' },
          { key: '195GMX', label: '195GMX' },
        ],
      },
      {
        name: 'GLX Series',
        options: [
          { key: '230GLX', label: '230GLX' },
          { key: '290GLX', label: '290GLX' },
          { key: '340GLX', label: '340LX' },
          { key: '400GLX', label: '400GLX' },
          { key: '475GLX', label: '475GLX' },
          { key: '565GLX', label: '565GLX' },
          { key: '675GLX', label: '675GLX' },
          { key: '800GLX', label: '800GLX' },
          { key: '1025GLX', label: '1025GLX' },
          { key: '1210GLX', label: '1210GLX' },
          { key: '1435GLX', label: '1435GLX' },
        ],
      },
      {
        name: 'GL Series',
        options: [
          { key: '180GL', label: '180GL' },
          { key: '230GL', label: '230GL' },
          { key: '290GL', label: '290GL' },
          { key: '340GL', label: '340GL' },
          { key: '475GL', label: '475GL' },
          { key: '565GL', label: '565GL' },
          { key: '675GL', label: '675GL' },
          { key: '800GL', label: '800GL' },
          { key: '1025GL', label: '1025GL' },
          { key: '1025GL-48V', label: '1025GL-48V' },
          { key: '1210GL-48V', label: '1210GL-48V' },
          { key: '1210GL', label: '1210GL' },
          { key: '1435GL', label: '1435GL' },
          { key: '1435GL-48V', label: '1435GL-48V' },
          { key: '1770GL', label: '1770GL' },
          { key: '2110GL', label: '2110GL' },
        ],
      },
    ],
  },
  TurnInDate: {
    key: 'TurnInDate',
    type: 'dateRange',
    placeholder: 'Enter date range',
    name: 'Turn-in Date',
    dateFormat: 'MM/DD/YYYY',
  },
  ShipDate: {
    key: 'CurrentShipDate',
    type: 'dateRange',
    placeholder: 'Enter date range',
    name: 'Ship Date',
    dateFormat: 'MM/DD/YYYY',
  },
  ApplicationId: {
    key: 'ApplicationId',
    type: 'select',
    name: 'Application Name',
  },
  ProductId: {
    key: 'ApplicationId',
    type: 'multiSelect',
    name: 'Product',
    options: GetFilterDropdown(Products),
  },
  FeedbackUser: {
    key: 'UserName',
    type: 'select',
    name: 'Feedback UserName',
  },
  UserId: {
    key: 'UserId',
    type: 'select',
    name: 'Feedback UserId',
  },
  Country: {
    key: 'Country',
    type: 'select',
    name: 'Country',
  },
  Condition: {
    key: 'Condition',
    type: 'select',
    name: 'Condition',
  },
  State: {
    key: 'State',
    type: 'select',
    name: 'State',
  },
};


export const SubFilterConfig: SubFilterMetadata = {
    assetTable: ['AssetTypeId', 'Manufacturer', 'Model'],
    feedbackAllTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    feedbackHRTConnectTable: [
      'FeedbackType',
      'Company',
      'FeedbackPriority',
      'FeedbackStatus',
      'InternalOnly',
      'PmDiscussion'
    ],
    feedbackMyTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    feedbackOmniTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    feedbackRTSelectTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    feedbackBluRedCareTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    feedbackBluRedSelectTable: ['FeedbackType', 'Company', 'ProductId', 'FeedbackPriority', 'FeedbackStatus', 'InternalOnly', 'PmDiscussion'],
    dashboardIotAd: ['AnnunciationStatus'],
    orderStatus: ['RepOffice', 'SalesManager', 'ProjectManager', 'Planner', 'EndUser', 'Customer', 'OSState', 'CompressorModels', 'TurnInDate', 'ShipDate']
  };