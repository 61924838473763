import { Dropdown } from "./filterConfigurationTypes";

export enum FeedbackProduct {
    GEAOmni = 3,
    ScrewCompressor = 6,
    ScrewChiller = 7,
    ScrewHeatPump = 8,
    PistonCompressor = 9,
    PistonChiller = 10,
    PistonHeatPump = 11,
    Purger = 12,
    AmmoniaDryer = 13,
    LiquidSeparator= 14,
    Vessel = 15,
    PumpSkid = 16,
    Miscellaneous = 17,
    X_Series = 19,

}

export const Products: EnumList[] = [
    { Id: FeedbackProduct.GEAOmni, Name: "GEA Omni"},
    { Id: FeedbackProduct.ScrewCompressor, Name: "Screw Compressor"},
    { Id: FeedbackProduct.ScrewChiller, Name: "Screw Chiller"},
    { Id: FeedbackProduct.ScrewHeatPump, Name: "Screw Heat Pump"},
    { Id: FeedbackProduct.PistonCompressor, Name: "Piston Compressor"},
    { Id: FeedbackProduct.PistonChiller, Name: "Piston Chiller"},
    { Id: FeedbackProduct.PistonHeatPump, Name: "Piston Heat Pump"},
    { Id: FeedbackProduct.Purger, Name: "Purger"},
    { Id: FeedbackProduct.AmmoniaDryer, Name: "Ammonia Dryer"},
    { Id: FeedbackProduct.LiquidSeparator, Name: "Liquid Separator"},
    { Id: FeedbackProduct.Vessel, Name: "Vessel"},
    { Id: FeedbackProduct.PumpSkid, Name: "Pump Skid"},
    { Id: FeedbackProduct.X_Series, Name: "X Series"},
    { Id: FeedbackProduct.Miscellaneous, Name: "Miscellaneous"},
];

const productDictionary = Products.reduce((acc: ProductDictionary, product) => {
    acc[product.Id] = product.Name;
    return acc;
}, {});

interface ProductDictionary {
    [key: number]: string
}

export function GetFilterDropdown(list: EnumList[]): Dropdown[] {
    const formattedList: Dropdown[] = [];
    list.forEach(item => {
        formattedList.push({
            key: item.Id,
            label: item.Name
        });
    });
    return formattedList;
}

export function GetProductText(id: number = -1) {
    let textData: string = productDictionary[id];
    if (textData) {
        return textData;
    } else {
        return "None";
    }
}

export function GetProductListText(ids: number[]): string[] {
    let productList: string[] = [];
    if (ids.length > 0) {
        ids.map(id => {
            const name = productDictionary[id];
            if (name)
                productList.push(name);
        });
    }
    return productList;
}

export function GetProductList(ids:string) {
    const idNums: number[] = ids.split(',').map(x => parseInt(x));
    return GetProductListText(idNums);
}

export interface EnumList {
    Id: number,
    Name: string
}