import { Environment as EnvironmentEnum } from '@gea/digital-ui-lib';

import { Environment } from './model';
export const environment: Environment = {
  name: EnvironmentEnum.PROD,
  production: true,
  endpoint: 'https://hrtconnectprod.azurewebsites.net/api/',
  oAuth: {
    clientId: '0f836a1a-815d-4e28-a1e5-6926fd4a2796',
    authority:
      'https://login.portal.gea.com/geaid.onmicrosoft.com/b2c_1_signin_signup/',
    authorityDomain: 'login.portal.gea.com',
    postLogoutRedirectURL: 'https://hrtconnect.apps.gea.com/',
    redirectURL: 'https://hrtconnect.apps.gea.com/',
    passwordResetAuthority:
      'https://login.portal.gea.com/geaid.onmicrosoft.com/B2C_1_RESET_PASSWORD_BY_EMAIL_VERIFICATION',
  },
  baseURL: 'https://apim-gea-prod.azure-api.net/',
  interceptorURLs: [
    'https://apim-gea-prod.azure-api.net/',
    'https://hrtconnect.apps.gea.com/',
  ],
  storageURL: 'https://strgaccb2c.blob.core.windows.net/strg-container-b2/',
  localizationStorageURL: 'https://content.apps.gea.com/strg-container-i18n/',
  portal: {
    baseURL: 'https://portal.gea.com/',
    redirectURL: 'https://apim-gea-prod.azure-api.net/',
  },
};
